import React from "react"
import { Link } from "gatsby"

const CookiesPolicy = ({cssClass}) => {

    return (
        <>
        <div className={cssClass}>
            
            <div className={`${cssClass}_section`}>
                <p>This Cookie Policy describes how we use and process cookies, trackers and other similar technologies when providing our services and upon access of the following websites or applications:</p>
                <ul>
                    <li>go-read.co.uk</li>
                    <li>go-write.co.uk</li>
                    <li>go-apps.app</li>
                    <li>gowrite.app</li>
                    <li>goread.app</li>
                </ul>
                <p>It also covers Your rights to control Our use of them. This Cookie Policy forms a part of our <Link to="/privacy" className="link">Privacy Policy</Link>.</p>
            </div>

            <section className={`${cssClass}_section`}>
                <h2>What are cookies?</h2>
                <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
                <p>You can choose to accept or modify the settings of certain cookies.</p>
            </section>

            <section className={`${cssClass}_section`}>
                <h2>Cookie Types</h2>
                <h3 className="h3">First Party Cookies</h3>
                <p>These are cookies set by us, either for “essential” reasons to let the website operate or to help us better understand users and enhance their experience.</p>
                <h3 className="h3">Third Party Cookies</h3>
                <p>Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). Some are essential for functionality. The parties that set these third party cookies can recognize your computer both when you visit the website in question and also when you visit certain other websites.</p>
            </section>

            <section className={`${cssClass}_section`}>
                <h2>How do we use cookies?</h2>
                <p>The specific types of first and third party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):</p>
                <div>
                    <h3 className="h3">Essential website cookies:</h3>
                    <p>These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</p>
                    <table className={`${cssClass}_table`} cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className={`${cssClass}_table_col1`}>Name</th>
                                <th className={`${cssClass}_table_col2`}>Purpose</th>
                                <th className={`${cssClass}_table_col3`}>Type/Provider</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>gatsby-gdpr-google-analytics</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>Used to record whether you have consented to Google Analytics cookies.</td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>first-party (https cookie)</td>
                            </tr>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>gatsby-gdpr-facebook-pixels</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>Used to record whether you have consented to Facebook Pixel cookies</td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>first-party (https cookie)</td>
                            </tr>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>GW1CookieConsent</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>Used to record whether you have consented to our cookie policy</td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>first-party (https cookie)</td>
                            </tr>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>goapps_prefs</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>Used to record whether you have consented to our cookie policy along with your preferences of the type of cookies you accepted.</td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>first-party (https cookie)</td>
                            </tr>
                            <tr>
                                <td data-label="Name">_GRECAPTCHA</td>
                                <td data-label="Purpose">Used to filter spam traffic and allow only legitimate visitors to use GoApps services.</td>
                                <td data-label="Type"><div>Third Party (local storage)<br/>provider: <a className="link" href="https://policies.google.com/privacy">Google</a></div></td>
                            </tr>
                            <tr>
                                <td data-label="Name">bugsnag-anonymous-id</td>
                                <td data-label="Purpose">Used to help us identify real-time errors in our websites or applications. No personal data is stored.</td>
                                <td data-label="Type"><div>Third Pary (local storage)<br/>Provider: <a className="link" href="https://www.bugsnag.com/">bugsnag.com</a></div></td>
                            </tr>
                            <tr>
                                <td data-label="Name">i18nextLng</td>
                                <td data-label="Purpose">Used to identify and store the display language preference</td>
                                <td data-label="Type">First Party (local storage)</td>
                            </tr>
                            <tr>
                                <td data-label="Name">
                                    <ul>
                                        <li>go-portal-teacher-token</li>
                                        <li>go-read-teacher-token</li>
                                        <li>go-write-teacher-token</li>
                                        <li>go-parents-desktop-token</li>
                                        <li>go-parents-mobile-token</li>
                                        <li>go-portal-pupil-token</li>
                                        <li>go-write-pupil-token</li>
                                        <li>go-read-pupil-token</li>
                                    </ul>
                                </td>
                                <td data-label="Purpose">Used to authenticate your session and store your logged in status</td>
                                <td data-label="Type">First Party (local storage)</td>
                            </tr>
                            <tr>
                                <td data-label="Name">
                                    <ul>
                                        <li>go_teacher_user</li>
                                        <li>go_parent_user</li>
                                        <li>go_pupil_user</li>
                                    </ul>
                                </td>
                                <td data-label="Purpose">Stores information related to your logged in session</td>
                                <td data-label="Type">First Party (local storage)</td>
                            </tr>
                            <tr>
                                <td data-label="Name">previousNav</td>
                                <td data-label="Purpose">Stores information to help you navigate around our site</td>
                                <td data-label="Type">First Party (local storage)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3 className="h3">Performance and functionality cookies</h3>
                    <p>These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.</p>
                    <table className={`${cssClass}_table`} cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className={`${cssClass}_table_col1`}>Category</th>
                                <th className={`${cssClass}_table_col2`}>Names</th>
                                <th className={`${cssClass}_table_col3`}>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>Vimeo</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>
                                    <ul>
                                        <li>AF_SYNC</li>	
                                        <li>_gid</li>
                                        <li>afUserId</li>	
                                        <li>_uetsid</li>	
                                        <li>_abexps</li>	
                                        <li>_pin_unauth</li>	
                                        <li>_fbp</li>	
                                        <li>_ga</li>
                                        <li>_gcl_au</li>
                                        <li>_uetvid</li>	
                                        <li>OptanonConsent</li>
                                        <li>vuid</li>
                                    </ul>
                                </td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>We embed videos from Vimeo. When you press play Vimeo will drop third party cookies to enable the the video to play and to collect analytics data such as how long a viewer has watched the video. These cookies do not track individuals unless you are simultaneously logged in to a Vimeo account when Vimeo may collect tracking data about your interaction with these videos.<br/>
                                    <a className="link" href="https://vimeo.com/privacy">vimeo.com/privacy</a>
                                </td>
                            </tr>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>Tawk.to</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>
                                    <ul>
                                        <li>TawkConnectionTime</li>
                                        <li>twk_token</li>
                                        <li>twk_[unique id]</li>
                                        <li>__tawkuuid</li>
                                    </ul>
                                </td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>We use Tawk.to to provide a live and offline chat facility. When you interact with the chat feature Tawk.to will use cookies enable this functionalty. These cookies use tracking to ensure your chat session is maintained between pages.<br/>
                                    <a className="link" href="https://help.tawk.to/article/what-are-tawkto-cookies-and-what-do-they-do">help.tawk.to/article/what-are-tawkto-cookies-and-what-do-they-do</a>
                                </td>
                            </tr>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>Stripe</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>
                                    <ul>
                                        <li>__stripe_sid</li>
                                        <li>__stripe_mid</li>
                                    </ul>
                                </td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>We use Stripe as a payment processor. Stripe uses these cookies for fraud prevention and detection, security and payment functionality.<br/>
                                    <a className="link" href="https://stripe.com/cookies-policy/legal">stripe.com/cookies-policy/legal</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3 className="h3">Analytics and customization cookies:</h3>
                    <p>These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our marketing campaigns are, or to help us customize our Websites for you.</p>
                    <table className={`${cssClass}_table`} cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className={`${cssClass}_table_col1`}>Category</th>
                                <th className={`${cssClass}_table_col2`}>Names</th>
                                <th className={`${cssClass}_table_col3`}>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>Google Analytics</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>
                                    <ul>
                                        <li>_ga</li>
                                        <li>_gid</li>
                                    </ul>
                                </td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>We use Google Analytics to collect information about how visitors use our website. We use the information to compile reports and to help us improve the websites. The cookies collect information in a way that does not directly identify anyone, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.<br/>
                                    <a className="link" href="https://support.google.com/analytics/answer/6004245">support.google.com/analytics/answer/6004245</a>
                                </td>
                            </tr>
                            <tr>
                                <td data-label="Name" className={`${cssClass}_table_col1`}>Facebook</td>
                                <td data-label="Purpose" className={`${cssClass}_table_col2`}>_fbq</td>
                                <td data-label="Type" className={`${cssClass}_table_col3`}>Where we use facebook advertising for our products and services. Facebook sets this cookie to help track the effectiveness of any adverts.<br/>
                                    <a className="link" href="https://en-gb.facebook.com/policy/cookies/">en-gb.facebook.com/policy/cookies/</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3 className="h3">Advertising cookies:</h3>
                    <p>These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</p>
                    <p><strong>We currently do not use any Advertising cookies. If we add any in the future your cookie-consent will be re-triggered.</strong></p>
                </div>
                <div>
                    <h3 className="h3">Unclassified cookies:</h3>
                    <p>These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.</p>
                    <p><strong>We currently do not use any Unclassified cookies. If we add any in the future your cookie-consent will be re-triggered.</strong></p>
                </div>
            </section>
            <section className={`${cssClass}_section`}>
                <h2>Managing cookie preferences</h2>
                <p>Most modern browsers make it possible to delete or disable cookies which have already been placed on your device. By disabling or deleting cookies, it is possible that parts of our website will no longer work.</p>
                <p>The following links may help you find out how to disable or delete the cookie settings in your browser:</p>
                <ul>
                    <li><a className="link" href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></li>
                    <li><a className="link" href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox">Firefox</a></li>
                    <li><a className="link" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                    <li><a className="link" href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Edge</a></li>
                    <li>Safari <a className="link" href="https://support.apple.com/en-gb/HT201265">Mobile Devices (iOS)</a> or <a className="link" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Desktops and Laptops (macOS)</a></li>
                    <li><a className="link" href="https://www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
                </ul>
            </section>
        </div>
        </>
    )

}

export default CookiesPolicy