import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout';
import CookiesPolicy from '../components/terms/cookies-policy'
import Cookies from "universal-cookie"
import { Modal } from "../components/modal"
import CookiePrefs from "../components/forms/cookie-prefs"

const CookiesPage=({ location }) => {

    const [showSettings, setShowSettings] = useState(false)
    const [cookiePrefs, setCookiePrefs] = useState({analytics:false, performance:false})

    const cookies = new Cookies()
    const cookie = cookies.get("goapps_prefs")
    const cookieVersion = "1.0"
    const cookieExpirey = 30 * 24 * 60 * 60

    const getCookieSetting = (settingName) => {
        return (cookie) ? cookie[settingName] : false
    }
    
    useEffect(() => {
        setCookiePrefs({ 
            performance:getCookieSetting('performance'), 
            analytics:getCookieSetting('analytics')
        })
    }, [])

    const submitForm = (e) => {
        e.preventDefault()
        var data = new FormData(e.target)
        cookies.set('goapps_prefs', JSON.stringify({
                set:true, 
                performance:data.get('performance') != null, 
                analytics:data.get('analytics') != null,
                version:cookieVersion
            }),
            {maxAge:cookieExpirey, sameSite:"lax", secure: true}
        )
        window.location.reload();
    }

    return(
        <>
            <Helmet>
                <title>GoWrite&trade; Cookies Policy</title>
                <meta name="description" content="About the cookies we use" />
            </Helmet>
            <Layout location={location}>
                <Container className="py-3">
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <h1 className="heading mb-half">Cookies Policy</h1>
                            <h2 className="headingSmall highlight">V2 - Last updated: August 2021</h2>
                            <button onClick={() => setShowSettings(true)} className="primary-btn mb-1">Set Cookies Preferences</button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <CookiesPolicy cssClass="legal" />
                        </Col>
                    </Row>
                </Container>
                {showSettings && (
                    <Modal closeModal={() => setShowSettings(false)}>
                        <div className="cookiePrefsModal">
                            <CookiePrefs submitHandler={ (e) => submitForm(e) } settings={cookiePrefs} />
                        </div>
                    </Modal>
                )}
            </Layout>
        </>
    )
}

export default CookiesPage